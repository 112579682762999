import React, { useState } from "react";
const extend = require("emmet").default;

const App = () => {
	const [input, setInput] = useState("");
	let converted = "";
	try {
		converted = extend(input);
	} catch {
		converted = "Error in Emmet";
	}
	return (
		<div className="p-4 md:p-8 dark:text-white flex flex-col gap-8 justify-center items-center">
			<div className="xl:w-1/2 w-full">
				<h1 className="xl:text-6xl mt-10 text-3xl text-center">
					Emmet Extender
				</h1>
				<div className="p-8 flex flex-col gap-4">
					<textarea
						value={input}
						onChange={({ target: { value } }) => setInput(value)}
						cols={30}
						className="border-2 border-dg bg-transparent outline-none ring-slate-500 ring-0 focus:ring-4 transition-all w-full p-4"
					/>
					<textarea
						readOnly
						value={converted}
						className="border-2 border-dg h-fit bg-transparent outline-none cursor-copy transition-all w-full p-4"
					/>
				</div>
			</div>
		</div>
	);
};

export default App;
